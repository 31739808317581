.linechar {
  &-wap {
    padding: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px 0px #00000026;
  }

  &-filter {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &-legen {
    display: flex;
    margin-bottom: 0px;

    &-items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-right: 10px;

      p {
        margin-bottom: 0px;
      }
    }
  }
  .circle {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    display: flex;
    &-blue {
      background: #2d9cdb;
    }
    &-red {
      background: #ff5b5b;
    }
    &-grey {
      background: #a0a3bd;
    }
  }
}
