.piechar {
  &-wap {
    padding: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px 0px #00000026;
  }

  &-filter {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  &-legen {
    display: flex;
    margin-bottom: 0px;

    &-items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-right: 10px;

      p {
        margin-bottom: 0px;
      }
    }
  }
  .custom-tooltip {
    background-color: #ffff;
    padding: 5px 45px 5px 5px;
    border: 1px solid #ccc;
  }
}
