$width_menu: 275px;
$width_menu_none: 275px;
$margin_left_body: $width_menu_none;
$color_text: #455571;
$color_text_hover: #2e3192;
// define common variable
$basic_color: #002643;
$grey_color: #8898aa;
$bg-color: #f1f3f9;
$white_color: #ffffff;
$transtion_time: 0.3s;
$color_text_primary: #0e2431;
$red_color: red;

@font-face {
  font-family: SFUIText;
  src: url(../../fonts/SFUIText-Regular.ttf);
}
@font-face {
  font-family: SFUITextBold;
  src: url(../../fonts/SFUIText-Bold.ttf);
}
@font-face {
  font-family: SFUIText-Medium;
  src: url(../../fonts/SFUIText-Medium.ttf);
}
