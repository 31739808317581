.icon {
  display: flex;
  align-items: flex-end;
  .btn-icon-btn {
    width: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.btn-icon {
  width: 18px;
}
.event-status {
  text-decoration: underline;
  color: #00a2ff;
}
.list-img {
  img {
    min-width: 150px;
    max-width: 100%;
    border-radius: 10px;
    height: 150px;
  }
}
.custom-text {
  textarea.input-form.form-control {
    height: 100px;
  }
  .ck-content {
    height: 150px;
    max-height: 250px;
  }
}
