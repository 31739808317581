.tags-input {
  display: inline-flex;
  margin: 0 auto;
  width: 600px;
  width: 100%;
  margin-bottom: 30px;

  font-size: 14px;
  border: 1px solid #6e7191 !important;
  border-radius: 3px;

  .form-group {
    margin-bottom: 0px !important;

    input {
      border: none !important;
    }
  }
}
.tags-input .tag {
  display: flex;
  align-items: center;
  margin: 5px;
}
.tags-input input {
  width: 100%;
  border: none;
  outline: none;
}

.tag {
  display: inline-flex;
  padding: 2px 5px;
  background-color: #cecece;
  border-radius: 3px;
}

button.delete {
  display: inline-block;
  margin-left: 5px;
  padding: 0;
  width: 15px;
  height: 15px;
  background-color: rgba(52, 32, 32, 0.3);
  color: white;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;
}
button.delete:after {
  content: 'X';
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
}

.help {
  font-size: 12px;
  color: #555;
}
