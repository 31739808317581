.box-user {
	width: 70px;
	height: 70px;
	margin-right: 20px;
	position: relative;

	.status-user {
		width: 100%;
		height: 100%;
		background-color: transparent;
		border: 1px solid #fff;
		border-radius: 5px;
		box-sizing: border-box;
		overflow: hidden;
		cursor: pointer;

		&-icon {
			width: 100%;
			height: 100%;
			transition: 0.5s;

			&:hover {
				transform: scale(1.08);
			}
		}

		&-on {
			width: 9px;
			height: 9px;
			background-color: #d14909;
			border-radius: 50%;
			position: absolute;
			top: -2px;
			right: -1.5px;
			z-index: 10;
		}

		&-name {
			text-align: center;
			
			p {
                color: #fff;
                font-size: 0.9rem;
                transition: 0.5s;
			}
		}
	}
}
