.box-avatar {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  &__notification {
    width: 36px;
    height: 36px;
    background-color: #d9dbe9;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    border: thin solid #ffffff;

    img {
      max-width: 36px;
      max-height: 36px;
      min-width: 36px;
      min-height: 36px;
      border-radius: 50%;
      transition: 0.5s;
      object-fit: cover;
    }

    &:hover {
      img {
        transform: scale(1.08);
      }
    }
  }

  &-content {
    display: flex;
    gap: 8px;

    &-name {
      .name-user {
        font-weight: 600;
        line-height: 20px;
        font-size: 16px;
        text-transform: capitalize;

        margin-bottom: 0px;
        color: #fff;
      }

      .permission-user {
        font-weight: 400;
        line-height: 12px;
        font-size: 12px;
        text-transform: capitalize;

        margin-bottom: 0px;
        color: #fff;
      }

      // p {
      //   margin-bottom: 0rem;
      //   color: #fff;
      //   transition: 0.5s;
      //   &:hover {
      //     text-shadow: 0 0 2px;
      //   }
      // }

      // &:hover {
      //   .box-avatar-info {
      //     display: block;
      //   }
      // }
    }
  }
  &-info {
    display: none;
    padding: 10px;
    position: absolute;
    background-color: #fff;
    width: 200px;
    top: 85px;
    right: 15px;
    z-index: 2001;
    &-avatar {
      display: flex;
      margin-bottom: 5px;
      img {
        display: block;
        text-align: center;
        max-width: 40px;
        max-height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-radius: 5px;

        border: thin solid #000000b4;
      }
      &-name {
        display: block;
        width: 140px;
        padding-left: 10px;
        padding-top: 10px;
        p {
          color: #000000;
          text-shadow: 0 0 1px;
        }
      }
    }

    &-footer {
      padding-top: 5px;
      a {
        &:hover {
          text-decoration: none;
        }
      }

      &-box {
        display: flex;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;

          margin: 5px;
          margin-left: 0;
        }
        p {
          margin-top: 0.1rem;
          color: #000000;
        }
        transition: 0.5s;
        &:hover p {
          text-shadow: 0 0 0.5px;
        }
      }
    }
  }
  &:hover {
    .box-avatar-info {
      display: block;
    }
  }
}
.avatar-image {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
}

.avatar-title {
  color: #212121;
  font-family: SFUIText-Bold;
  font-weight: bold;
  font-size: 14px;
}

.avatar-description {
  font-family: SFUIText-Regular;
  font-size: 10px;
}
