.body-right-bottom {
  background-color: white !important;
}

.container-fluid {
  .top-table {
    padding-left: 24px !important;

    &-title {
      p {
        font-family: 'SF-Pro-Display';
        font-size: 48px;
        line-height: 58px;
      }
    }
  }

  .dashboard {
    .col-md-12 {
      padding: 0px !important;
    }

    .form-group {
      margin-bottom: 24px !important;
    }

    &-select {
      .col-md-3 {
        padding: 0px 12px;
      }

      .form-control-select {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        text-align: left;
        color: #a0a3bd;

        padding-right: 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        option {
          font-size: 16px !important;
          font-weight: 400 !important;
          line-height: 24px !important;
          color: black;
        }
      }
    }

    &-statistic {
      .col-md-4 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }
      .col-md-3 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }
    }

    &-char {
      .col-md-8 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }
      .col-md-4 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }
      &-line {
      }

      &-pie {
      }
    }

    &-table {
      .col-md-8 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }

      .col-md-4 {
        padding: 0px 12px;
        margin-bottom: 24px;
      }

      &-activate {
      }

      &-student {
      }
    }
  }
}
