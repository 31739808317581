*,
*:after,
*:before {
  box-sizing: border-box;
}

.containers {
  position: relative;
  border-radius: 100px;
}
.tabs {
  display: flex;
  background-color: #f7f7fc;
  border-radius: 100px;
  padding: 4px 0px;
  margin-bottom: 0px;
  * {
    z-index: 2;
  }
}

input[type='radio'] {
  display: none;
}

.tab {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  border-radius: 100px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  width: 120px;
  height: 45px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #999999;
}

input[type='radio'] {
  &:checked {
    & + label {
      color: #e6eef9;
    }
  }
}

input[id='age-1'] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id='age-2'] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

input[id='age-3'] {
  &:checked {
    & ~ .glider {
      transform: translateX(200%);
    }
  }
}

input[id='belt-1'] {
  &:checked {
    & ~ .glider {
      transform: translateX(0);
    }
  }
}

input[id='belt-2'] {
  &:checked {
    & ~ .glider {
      transform: translateX(100%);
    }
  }
}

input[id='belt-3'] {
  &:checked {
    & ~ .glider {
      transform: translateX(200%);
    }
  }
}

input[id='belt-4'] {
  &:checked {
    & ~ .glider {
      transform: translateX(300%);
    }
  }
}

.glider {
  position: absolute;
  display: flex;
  width: 120px;
  height: 45px;
  background-color: #f22355;
  z-index: 1;
  border-radius: 100px;
  transition: 0.25s ease-out;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(0.6);
  }
}
