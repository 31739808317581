.buttons {
  width: 100%;
  display: flex;
  &-btn {
    width: 50%;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fff;

    border-top: 1px solid #2e3192;
    border-bottom: 1px solid #2e3192;
    border-right: 0.5px solid #2e3192;
    box-sizing: border-box;

    cursor: pointer;
    transition: 0.5s;
    p {
      display: block;
      margin-bottom: 0 !important;

      font-size: 0.7rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      text-transform: uppercase;
      color: #8898aa;

      transition: 0.5s;
    }
    &--left {
      border-radius: 5px 0px 0 5px;
      border-left: 1px solid #2e3192;
    }
    &--right {
      border-radius: 0px 5px 5px 0px;
      border-right: 1px solid #2e3192;
    }
    &--active {
      background-color: #2e3192;
      transition: 0.5s;
      p {
        color: #fff;
      }
      &:hover {
        background-color: #090b5c !important;
        p {
          color: #cfcc10 !important;
        }
      }
    }
    &:hover {
      p {
        color: #000;
      }
      background-color: #c8c4c4;
    }
  }
}
