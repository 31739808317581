.form-search {
  width: 100%;
  height: 46px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #6e7191;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background-color: $white_color;

  &-icon {
    width: 20px;
    height: 20px;
    margin: 10px;
  }

  &-input {
    width: 270px;
    border: transparent;
    color: #455571;
    background-color: $white_color;
    padding-left: 15px;
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #a0a3bd;
    }
  }
}
