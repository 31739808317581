@import './variables/variables.scss';
@import './animation/animation.scss';
@import './custom/custom.scss';
@import './responsive/responsive.scss';
@import './common/date_picker.scss';
@import './common/input.scss';
@import './common/table.scss';
@import './common/button.scss';
@import './profile/profile.scss';
@import './login/login.scss';
@import './register/register.scss';
@import './menu/menu.scss';
@import './common/pagenatie.scss';
@import './header/header.scss';
@import './statistic/statistic.scss';
@import './club/club.scss';
@import './content/content.scss';

@import './student/student.scss';
@import './common/input_search.scss';
@import './common/select.scss';
@import './common/status_user.scss';
@import './common/avatar.scss';
@import './common/checkout.scss';

@import './common/table.scss';
@import './common/checkbox.scss';
@import './common/avatar.scss';
@import './common/buttons.scss';
@import './common/belt.scss';
@import './common/form_add.scss';
@import './common/pagination.scss';
@import './common/table_custom.scss';
@import './common/information.scss';
@import './common/box_belt.scss';

@import './document/document.scss';
@import './permission/permission.scss';
@import '../styles/dashboard/dashboard.scss';
@import './competition/competition.scss';
@import './exam/exam.scss';
@import './donate/donate.scss';
@import './course/course.scss';
@import './bug/fixbug.scss';

@import './postweb/postweb.scss';
@import './library/library.scss';
@import './event//event.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'SF-Pro-Display';
}

iframe {
  display: none;
}

//test display components
html {
}
body {
  background-color: #f1f3f9 !important;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(59, 42, 165, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 25px rgba(#5c65af, 1);
  }
}

a {
  text-decoration: none;
  color: #000;
}

.body-right {
  width: 100%;
  min-height: 101vh;
  padding-left: $margin_left_body;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: $transtion_time;

  background-color: $bg-color;

  &-bottom {
    width: 100%;
    background-color: $bg-color;
    padding: 24px;
  }
}

.page-wrapper {
  margin-left: 280px;
}

.container-shadow {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0px -5px 5px rgba(0, 0, 0, 0.09);
}
a:hover {
  text-decoration: none !important;
}

.swal2-title {
  font-size: 0.875em !important;
}

.ck.ck-editor__editable_inline {
  min-height: 200px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.swal2-input {
  height: 200px !important;
}

select {
  min-width: 100%;
  height: 48px;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #6e7191;
  background-color: $white_color;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px !important;

  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #a0a3bd;
  }
}

#text-area-arlet {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #e55934;
  margin-top: 8px;
}

.error-att {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #e55934;
  margin-top: 8px;
}

.body-right-bottom .form-control {
  height: 48px;
  border: 1px solid #6e7191 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

.content-form {
  padding: 0px;
}
.body-right-bottom .form-control-select {
  height: 48px;
  border: 1px solid #6e7191 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 24px 12px 16px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('./../images/icon/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
  cursor: pointer;

  font-family: 'SF-Pro-Text' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left;
  color: #a0a3bd !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  option {
    font-family: 'SF-Pro-Text' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
    color: #000 !important;
  }
  &:focus {
    box-shadow: none;
  }
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.body-right-bottom .form-control-custom {
  height: 48px;
  border: 1px solid #6e7191 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('../images/icon/arrow-down.svg');

  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
}

.body-right-bottom .form-control-white {
  height: 48px;
  border: 1px solid #6e7191 !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 16px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('./../images/icon/arrow-down-white.svg');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
  cursor: pointer;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Semibold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Heavy.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Display-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Text-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Text-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Text-Semibold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Text-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF-Pro-Text';
  src: url('./../../assets/fonts/sfProTextFont/SF-Pro-Text-Heavy.ttf');
  font-weight: 800;
  font-style: normal;
}
