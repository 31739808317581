.activate {
  box-shadow: 0px 0px 16px 0px #00000026;

  &-wrap {
    padding: 16px;
    background-color: #ffffff;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #0e2431;
      margin-bottom: 0px;
    }

    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      color: #0e2431;
      text-decoration: none;

      &:hover {
        color: #ed1c24;
      }
    }
  }
}
