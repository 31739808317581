.button-new {
  font-family: SFUIText;
  margin-right: 1rem;
  border-radius: 5px;
  background-color: #002643;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  span {
    padding: 0.5rem 1.5rem;
    display: inline-block;
    border: none;
  }
  &-cancel {
    background-color: #8898aa;
  }
  &-red {
    background-color: initial;
    margin-left: 15px;
    color: #ed1c24;
    border: 1px solid #ed1c24;
  }
  &-close {
    color: #ed1c24;
    background-color: initial;
    border: none;
    margin-left: 0;
    span {
      border-radius: 5px;
      border: 1px solid #ed1c24;
      padding: 12px;
      width: 48px;
      height: 48px;
    }
  }
  &:focus {
    outline: none;
  }
}

.btn-new {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;

  min-width: 132px;
  border-radius: 4px;
  height: 46px;

  background-color: $color_text_primary !important;
  border: 0px;

  svg {
    width: 16px;
    height: 16px;
  }

  span.lnr {
    font-weight: 600;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    color: $white_color;
  }

  &-event {
    span {
      font-size: 15px;
    }
  }

  &:hover {
    background-color: $white_color !important;
    border: 1px solid $color_text_primary;

    span {
      color: $color_text_primary;
    }

    svg {
      path {
        fill: $color_text_primary;
      }
    }
  }
}

.btn-draf {
  background-color: #bbc9da !important;
  color: #fff !important;
  align-items: center;
  transition: unset;
  min-width: 125px;
  border-radius: 8px;
  height: 46px !important;
  border: 1px solid #bbc9da !important;
  margin: 0 15px;

  &:hover {
    background-color: #696e74 !important;
    border: 1px solid #696e74 !important;
    color: #fff !important;
  }
}

.btn-cancel {
  background-color: #8898aa !important;
  font-family: SFUIText;
  span.lnr {
    font-weight: 600;
  }
  color: #fff !important;
  align-items: center;
  transition: unset;
  min-width: 125px;
  border-radius: 8px;
  height: 46px !important;
  border: 1px solid #8898aa !important;
  margin: 0 15px;
  &:hover {
    background-color: $red_color !important;
    border: 0px;
    color: #fff !important;
  }
}

.hei-40px {
  height: 40px;
}
