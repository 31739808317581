.input-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #012e50;
  padding: 0px;

  &-row {
    flex: 0 0 30%;
    max-width: 70%;
    margin: auto;
  }

  .required {
    color: red;
  }
  .input-form::placeholder {
    color: #a0a3bd;
  }
}

.required {
  color: red;
}

.invalid-feedback {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #e55934;
  margin-top: 8px;
  display: block;
}

.preview-img {
  width: 100%;

  &-avatar {
    border: 2px dashed #0e2431 !important;
    text-align: center;

    &-student {
      flex: 0 0 100%;
      border-radius: 10px;
      padding: 24px 0px;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        cursor: pointer;
        border-radius: 50%;
        max-width: 200px;
        min-width: 200px;
        max-height: 200px;
        min-height: 200px;
      }
      &-file {
        margin: 0px;
        display: flex;
        flex-direction: column;
        text-align: center;
        .main {
          margin: 8px 0px;
          padding: 0px;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #0e2431;
          font-family: 'SF Pro Text';
        }
        .sup {
          font-family: 'SF Pro Text';
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
        }
      }
    }

    &-border {
      border: 1px solid #6e7191;
      img {
        padding: 15px 0;
        border-radius: 0;
      }
      &-group {
        height: 300px;
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        border-radius: 7px;
        .upload-img-area {
          width: 100%;
          height: 100%;
        }
        img {
          padding: 0;
          max-width: 100%;
          min-width: 100%;
          max-height: 100%;
          min-height: 100%;
        }
      }
    }
  }
  &-input {
    border-radius: 5px;
    padding: 20px;
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    &-lib {
      padding-bottom: 0;
    }
    &-student {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .upload-label {
      margin: auto;
      font-family: SFUIText;
      font-size: 18px;
      line-height: 1.67;
      text-align: left;
      color: #172b4d;
      cursor: pointer;

      img {
        margin-left: 0.5rem;
      }
    }

    #uploading {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba($color: #fff, $alpha: 0.8);
      z-index: 99;
    }

    .upload-file {
      display: none;
      opacity: 0;
    }

    .image {
      height: 120px;
      width: auto;
      margin: 0 auto;
      display: inherit;
      margin-top: 20px;
    }
  }
}

.form-group {
  // margin-bottom: 30px !important;
  width: 100%;
  .ck-editor {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
  }
  .form-link {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #5fa1fc;
    margin-top: 10px;
    display: block;
  }

  .invalid-feedback {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #e55934;
    margin-top: 8px;
    display: block;
  }

  &.has-errors {
    .input-form {
      border-color: red;
      &-none {
        border-color: #ced4da;
      }
    }
  }

  .invalid-feedback {
    display: block;

    &-row {
      margin-left: 30%;
    }
  }
  select option {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left;
  }
}

textarea.input-form.form-control {
  height: 200px;
  max-height: 200px;
  overflow-y: hidden;
  border-radius: 4px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  // border: solid 1px #8898aa;
  background-color: #ffffff;
}
textarea.input-form-short.form-control {
  height: 136px;
  max-height: 295px;
  overflow-y: hidden;
  border-radius: 4px;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  // border: solid 1px #8898aa;
  background-color: #ffffff;
}

.input-form {
  font-family: 'Muli', sans-serif !important;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #576271;
  min-height: 48px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(23, 23, 23, 0.18);
  background-color: #ffffff;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;

  &-select {
    background-color: #2e3192;
    color: #fff;
    &:focus {
      background-color: #fff !important;
      color: #495057 !important;
    }
  }

  &-row {
    flex: 0 0 70%;
    max-width: 70%;
  }

  &-product {
    font-size: 18px;
    color: #0c0f66;
  }

  .form-input-day {
    height: 45px;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    transition: all 0.2s;
  }

  .react-date-picker__wrapper {
    border: none;
    cursor: pointer;
  }
  .react-datetime-picker__wrapper {
    border: none;
    cursor: pointer;
  }
}

.rdw-editor-main {
  height: 300px !important;
  border: 1px solid #ced4da;
}

.upload-field {
  border-radius: 4px;
  background-color: #ffffff;
  border: 2px dashed #0e2431;
  max-height: 168px;
  height: 100%;
  width: 100%;
  max-width: 522px;
  .input-label {
    top: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .react-multi-carousel-list {
    height: 95%;
    video {
      padding: 0 15px;
    }
  }

  .remove-custom {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &-caso {
      top: 30px;
    }
    img {
      max-width: 100% !important;
      max-height: 100% !important;
      min-width: 100% !important;
      min-height: 100% !important;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background-color: $basic_color !important;
      background: $basic_color !important;
      color: $basic_color !important;
      border-color: $basic_color !important;
    }
  }

  .react-multi-carousel-dot-list {
    bottom: 30px;

    button {
      height: 2px;
      width: 25px;
      border-radius: 5px;
    }
  }

  .react-multi-carousel-track {
    height: 85%;
  }

  .react-multi-carousel-item {
    text-align: center;

    img {
      max-height: 200px;
      min-height: 200px;
      max-width: 240px !important;
      min-width: 240px;
    }
  }

  height: 295px;
  margin-top: 29px;

  &--custom {
    margin-top: 0 !important;
  }

  .upload-input {
    display: none;
    visibility: hidden;
  }

  border-radius: 7px;
  position: relative;

  .preview-field {
    padding: 40px 0;

    .remove {
      color: $basic_color;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }

    .item-preview {
      display: flex;
      align-items: center;
      flex-direction: row;

      img {
        width: 30px;
        height: 35px;
        margin-right: 10px;
        min-height: 35px;
        min-width: 30px;
        max-width: 30px;
        flex-grow: 1;
      }

      .document-info {
        a {
          font-size: 14px;
          color: #10aee8;
          font-weight: 600;
          white-space: pre-wrap;
          word-break: break-word;
        }

        span {
          font-style: italic;
          font-size: 14px;
          color: #171725;
        }
      }
    }
  }
  &-ebook {
    margin-top: 0;
  }
}

.upload-field-zip {
  border-radius: 4px;
  min-width: 132px;
  height: 46px;

  .upload-input {
    display: none;
    visibility: hidden;
  }

  .upload-file-area {
    border-radius: 4px;
    background-color: #0e2431 !important;
    border: 2px solid #0e2431;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #0e2431 !important;

    cursor: pointer;
    transition: background-color 0.3s ease;

    svg {
      width: 24px;
      height: 24px;
      transform: rotate(180deg);
      path {
        fill: #ffffff;
      }
    }

    span.lnr {
      font-weight: 600;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
    }
    &:hover {
      background-color: #ffffff !important;
      p {
        color: #0e2431;
      }

      svg {
        path {
          fill: $color_text_primary;
        }
      }
    }
  }
}

.upload-area {
  display: flex;
  flex-wrap: nowrap;
  height: calc(100% - 40px);
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    max-width: 64px;
    max-height: 64px;
    cursor: pointer;
  }
  &-full {
    height: 100%;
  }
  &-file {
    margin: 0px;
    display: flex;
    flex-direction: column;
    text-align: center;
    .main {
      margin: 8px 0px;
      padding: 0px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #0e2431;
      font-family: 'SF Pro Text';
    }
    .sup {
      font-family: 'SF Pro Text';
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #6e7191;
    }
  }
  &-item {
    margin: auto 0;
    margin-right: auto;
    padding: 0 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  &-carousel {
    margin: auto 0;
    margin-right: auto;
    padding: 0 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  &-list {
    width: 100%;
    height: calc(100% - 40px);
    overflow-x: auto;
    display: flex;

    &-item {
      padding: 15px;
      position: relative;
      flex: 0 0 200px;
      max-width: 200px;
      width: 200px;
      height: 200px;
      display: inline-block;
      margin-top: 1rem;
    }
  }

  &-item {
    position: relative;
    .close-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
    }

    & > div {
      margin: auto;
      max-width: 100px;
      text-align: center;
      & > img {
        max-width: 100px;
        max-height: 120px;
      }
    }

    .count-item {
      font-size: 24px;
      color: $basic_color;
    }
  }
}

.fancybox-container {
  .fancybox-image {
    animation-name: unset;
    animation-duration: unset;
    transition-timing-function: unset;
    animation-fill-mode: unset;
    animation-delay: unset;
  }
  .fancybox-thumbs__list {
    transition: unset;
    a {
      animation-name: unset;
      animation-duration: unset;
      transition-timing-function: unset;
      animation-fill-mode: unset;
      animation-delay: unset;
    }
  }
}

.fancy-box > svg {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.fancybox-navigation .fancybox-button--arrow_right {
  display: none;
}

.fancybox-navigation .fancybox-button--arrow_left {
  display: none;
}

.upload-show-img {
  width: 100%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  &-max {
    height: 100%;
    width: 100%;
  }
}
.upload-show-img-full {
  margin-top: 2rem;
  width: 80%;
  margin-left: 10%;
  // height: 100%;
  object-fit: cover;
  object-position: center;
}

.product-button-delete {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
  color: #f53c56;
  border: 1px solid #f53c56;
  font-size: 24px;
  text-align: center;
  border-radius: 0.25rem;
  position: absolute;
  top: 5px;
  right: -30px;
  z-index: 10;
}

.group-photo {
  .form-group {
    margin-bottom: 30px !important;
  }
}

.icon-file {
  position: absolute;
  max-width: 30%;
  max-height: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  svg {
    max-width: 100%;
    max-height: 300px;
  }
}
.img-file {
  width: 300px;
  height: 300px;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1000;
  top: -300px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.suggestion-item {
  border-bottom: solid 1px #f0f5fe;
  padding: 15px;
  color: #2f456f;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: background-color 0.2s ease;
  font-size: 20.2084px;
  line-height: 30px;
  &--active {
    padding: 15px;
    font-size: 20.2084px;
    line-height: 30px;
  }
}

.react-datetime-picker__inputGroup__input {
  &:focus {
    outline: none !important;
  }
}

.upload-file-zip {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  min-width: 132px;
  border-radius: 4px;
  height: 46px;
  background-color: #0e2431 !important;
  border: 0px;
}

.ck-content iframe {
  width: 100%;
  height: auto;
  display: block !important;
}
