.dashboard-title {
  font-family: SFUIText;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.32;
}

.table-dashboard {
  margin-top: 25px;
  tr th:last-child {
    background-color: initial !important;
  }
  th {
    p {
      font-family: SFUIText;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      text-align: center;
      padding: 25px 0;
      margin-bottom: 0;
      border-top-left-radius: 5px;
      border: solid 0.5px #d1e1ef;
      border-bottom: solid 0px #d1e1ef;
    }
    &:last-child {
      p {
        border-top-left-radius: 0;
        border-top-right-radius: 5px;
      }
    }
  }
  td {
    p {
      font-family: SFUIText;
      font-size: 1rem;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      padding: 25px 0;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0;
      border: solid 0.5px #d1e1ef;
      border-bottom: solid 0px #d1e1ef;
    }
  }
  &-dark {
    p {
      color: #f9f9fe;
      background-color: #2e3192;
    }
    &-border {
      border-bottom-left-radius: 5px;
      border-bottom: none;
    }
  }
  &-light {
    p {
      color: #0c0f66;
      background-color: initial;
    }
    &-border {
      border-bottom-right-radius: 5px;
      border-bottom: solid 1px #d1e1ef !important;
    }
  }
  &-title {
    margin-bottom: 30px;
    & > p {
      margin: auto 0;
      font-family: SFUIText;
      font-size: 20px;
      font-weight: 600;
      line-height: 0.7;
      color: #002643;
      margin-bottom: 30px;
    }
    .button-new {
      padding: 15px;
      span {
        font-size: 10px;
      }
    }
  }
  &-total {
    text-align: center;
    margin: 2rem auto;
    span {
      font-family: SFUIText;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
      padding: 20px 75px;
      border-radius: 10px;
      background-color: #fc4300;
    }
  }
  &-customer {
    &-item {
      margin: auto;
      margin-bottom: 3rem;
      &-number {
        font-family: SFUIText;
        font-size: 19px;
        font-weight: bold;
        text-align: left;
        color: #001737;
        margin: auto 0;
      }
      &-text {
        font-family: SFUIText;
        font-size: 15px;
        margin-left: 5px;
        color: #0c0f66;
        margin: auto 5px;
      }
    }
  }
}

.dashboard-button {
  button {
    padding: 0 30px;
    border: none;
    height: 40px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ed1c24;
    background-color: initial;
    font-size: 11px;
    color: #4d4f5c;
    line-height: 1.55;
    &.active {
      background-color: #ed1c24;
      color: #ffffff;
    }
    &:focus,
    &:active {
      outline: none;
    }
    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.dashboard-arrow {
  border: solid 1px #172b4d;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  cursor: pointer;
}

.dashboard-arrow-disalbe {
  background-color: #cacaca;
  cursor: initial;
  user-select: none;
  border: none;
}
.react-date-picker__calendar {
  z-index: 100 !important;
}

.date-picker-remove {
  position: absolute;
  top: 25%;
  right: 60px;
  z-index: 99;
  transform: translate(-50%, 0);
  cursor: pointer;
}

.react-datetime-picker__calendar {
  z-index: 100 !important;
}

.recharts-legend-wrapper {
  bottom: -5px !important;
}
