.box-belt {
  margin: auto;
  width: 100px;
  background-color: #000;
  border-radius: 30px;
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    font-family: SFUIText;
    font-size: 0.9rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.18;
    letter-spacing: normal;
    text-align: left;
    color: #172b4d;
    color: #fff;
  }
}
