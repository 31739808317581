.login {
	width: 100vw;
	height: 100vh;
	transition: 0s !important;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	* {
		transition: 0s !important;
	}

	&-background {
		width: 100vw;
		height: 100vh;
		background-position: center;
		background-repeat: repeat;
		background-size: cover;
		position: absolute;
		top: 0;
		bottom: 0;
		z-index: -10;

		&-above {
			width: 100vw;
			height: 100%;
			position: absolute;
			top: 0;
			z-index: 0;
		}

		&-bot {
			width: 100vw;
			position: absolute;
			bottom: 0;
			z-index: 0;
		}
	}

	&-form {
		width: 450px;
		height: 500px;
		margin-top: 30px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #f7fafc;
		border-radius: 10px;
		box-shadow: 1px 1px 1px 1px #ccc;
		text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;

		&-wel {
			width: 100%;
			font-weight: bold;
			font-size: 0.9rem;
		}

		&-logo {
			display: block;
			width: 90px;
			height: 90px;
			margin: 30px;
		}

		&-input {
			margin-top: 20px;
			margin-bottom: 20px;
			width: 100%;
			height: 47px;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
				0 12.5px 10px rgba(0, 0, 0, 0.06);
			transition: 0.5s !important;

			input {
				border: transparent;
				width: 85%;
				font-size: 1rem;

				&:focus {
					outline: none;
				}
			}

			&-img {
				width: 20px;
				height: 20px;

				margin: 12px;
			}

			&:hover {
				box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
					0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072);
			}
			
			&:focus-within {
				box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
					0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
					0.3px 0px 6px rgba(29, 28, 28, 0.5);
			}
		}

		&-check {
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			&-custom {
				width: 50%;
				display: flex;
				position: relative;
				padding-left: 10px;
				margin-bottom: 10px;
				cursor: pointer;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				transition: 0.5s !important;
				input {
					position: absolute;
					opacity: 0;
					cursor: pointer;
					height: 0;
					width: 0;
					margin: 5px;
				}

				&-mark {
					position: absolute;
					top: 5px;
					left: 0;
					height: 20px;
					width: 20px;
					border-radius: 3px;
					background-color: #fff;
					box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
						0 12.5px 10px rgba(0, 0, 0, 0.06);
					transition: 0.5s !important;
					&:after {
						content: "";
						position: absolute;
						display: none;
						left: 6px;
						top: 2px;
						width: 8px;
						height: 13px;
						border: solid white;
						border-width: 0 3px 3px 0;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
					}
				}

				&-text {
					position: absolute;
					top: 5px;
					left: 30px;
					padding-left: 5px;
					font-size: 0.8rem;
					transition: 0.5s !important;

					&:hover {
						text-shadow: 0 0 1px black;
					}
				}

				&:hover input ~ .login-form-check-custom-mark {
					background-color: rgb(227, 235, 236);
					box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
						0 12.5px 10px rgba(0, 0, 0, 0.06), 0.3px 0px 4px rgba(29, 28, 28, 0.3);
				}

				input:checked ~ .login-form-check-custom-mark {
					background-color: #2196f3;
				}

				input:checked ~ .login-form-check-custom-mark:after {
					display: block;
				}
			}

			&-forgot {
				font-size: 0.8rem;
				cursor: pointer;
			}
		}

		&-btn {
			height: 45px;
			margin: 15px;
			background-color: #2e3192 !important;
			transition: 0.5s !important;
			&:hover {
				background-color: #060855 !important;
			}
		}
	}
	
	&-foot {
		display: flex;
		margin: 10px;

		&--ques {
			color: #fff;
		}
		&--res {
			color: #bd8634;
			cursor: pointer;
			transition: 0.5s !important;
			&:hover {
				color: #fb9906;
				text-shadow: 0 0 1px black;
			}
		}
	}
}
