.render-content-wrap {
  display: flex;
  justify-content: center;

  a {
    width: fit-content !important;
    div {
      width: fit-content !important;
    }
  }
}
