.header {
  width: 100%;
  height: 250px;
  background-position: bottom;
  background-repeat: repeat;
  background-size: cover;
  position: relative;

  &-bg-above {
    width: 100%;
    height: 100%;
    // background: linear-gradient(
    // 	270deg,
    // 	rgba(112, 84, 163, 0.7) 20%,
    // 	rgba(78, 64, 156, 0.7) 25%,
    // 	rgba(71, 62, 153, 0.7) 30%
    // );
    background-color: #0e2431;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    padding: 48px 29px 48px 24px;
  }
}

.header-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &-left {
    display: flex;
    // margin-left: auto;

    &-bell {
      width: 20px;
      height: 20px;
      margin: 10px 20px;
      cursor: pointer;

      img {
        width: 100%;
        transition: 1s;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }

    &-help {
      width: 20px;
      height: 20px;
      margin: 10px 20px;
      margin-right: 40px;
      cursor: pointer;

      img {
        width: 100%;
        transition: 1s;
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

.header-title {
  padding-top: 0rem;

  &-main {
    p {
      color: #fff;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  &-hr {
    width: 40px;
    height: 2.5px;
    background-color: #fff;
  }

  &-text {
    padding-top: 10px;
    p {
      color: #fff;
    }
  }
}

.header-change {
  &-users {
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .body-right-bottom {
    .top-table {
      &-title {
        p {
          font-size: 38px;
          line-height: 48px;
        }

        &::after {
          bottom: -8px;
          height: 3px;
        }
      }

      &-text {
        font-size: 20px;
        line-height: 24px;

        margin-top: 18px;
      }
    }
  }
}
