.information-clb {
	width: 100%;

	background-color: #fff;
	display: flex;
	flex-wrap: wrap;
	transition: 0.5s;
	&-upload {
		padding: 20px;

		background-color: #fff;

		&-img {
			width: 100%;

			cursor: pointer;
			overflow: hidden;

			display: flex;
			justify-content: center;
			align-items: center;

			transition: 0.5s;
			img {
				width: 90%;
				display: block;
				transition: 0.5s;
			}
			&:hover {
				img {
					transform: scale(1.08);
				}
			}
		}
	}
	&-info {
		padding: 20px;
		&-title {
			p {
				font-size: 21px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.19;
				letter-spacing: normal;
				text-align: left;
				color: #172b4d;
			}
		}
	}
	&-info-2 {
		width: 100%;
		padding: 10px;
		&-title {
			display: flex;
			p {
				margin-right: 20px;

				font-size: 21px;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.19;
				letter-spacing: normal;
				text-align: left;
				color: #172b4d;
			}
			img {
				width: 25px;
				height: 25px;

				cursor: pointer;
				transition: 0.5s;
				&:hover {
					transform: scale(1.05);
				}
			}
		}
		&-imgs {
			padding-top: 10px;
			padding-bottom: 20px;
			&-img {
				border-radius: 10px;
				margin-top: 10px;
				margin-bottom: 10px;

				overflow: hidden;
				cursor: pointer;

				position: relative;
				img {
					width: 100%;
					height: 200px;
					transition: 1s;
					&:hover {
						transform: scale(1.08);
					}
				}
				&-hover {
					width: 100%;
					height: 200px;
					background-color: #000;
					opacity: 0.1;

					position: absolute;
					top: 0;
					left: 0;
					z-index: 10;

					transition: 1s;
				}
				&:hover {
					.information-clb-info-2-imgs-img-hover {
						height: 0px;
					}
				}
			}
		}
	}
}

.information-memberClb {
	padding-top: 20px;
	transition: 0.5s;
}
