.post-web-content-wrap {
  .filter-wrap {
    display: flex;
    gap: 16px;

    margin-bottom: 24px;

    .search {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      .form-search {
        justify-content: flex-start;
        padding: 0px;
        margin-bottom: 0px;

        input {
          width: 100%;
        }
      }

      .form-group {
        width: fit-content;
        min-width: fit-content;
        margin-bottom: 30px !important;

        .input-form {
          min-height: 40px;
        }
      }
    }

    .type-technique {
      .form-group {
        margin-bottom: 30px !important;

        .form-control {
          min-height: 38px;
        }
      }
    }

    button {
      margin: 0px 5px 0 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .post-web-content-wrap {
    .filter-wrap {
      flex-direction: column;
      width: 100%;

      .search {
        flex-direction: column;

        .form-group {
          width: 100%;
        }
      }
    }
  }
}
