.menu {
  padding-bottom: 0px;
  border-bottom-right-radius: 10px;
  position: relative;

  &--left {
    position: fixed;
    height: 100%;
    width: $width_menu;
    transition: $transtion_time;
    top: 0;
    left: 0px;
    z-index: 999;

    overflow-y: scroll;
    overflow-x: hidden;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
  background: #fff;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  &-top {
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 48px 98px 64px 98px;

    a {
      width: 80px !important;
      height: 80px !important;
    }

    &-logo {
      width: 80px;
      height: 80px;
    }

    &-nav {
      width: 22px;
      height: 18px;
      cursor: pointer;
      margin: left;
      position: absolute;
      top: 48px;
      right: 16px;
      transition: $transtion_time;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .over-view {
    color: #000 !important;
  }

  .item-menu {
    display: flex;
    gap: 16px;

    .ps-menu-label {
      margin-left: 11px;
    }

    a {
      color: #000;
    }
  }
}

a.active {
  & > .item-menu {
    background-color: $color_text_primary;

    .naviga-icon {
      display: flex;
      align-items: center;

      g {
        path {
          fill: $white_color;
        }
      }
    }

    p {
      color: $white_color !important;
    }
  }
}

a.active-sub {
  & > .item-menu-sub {
    background-color: #d9dbe9;

    &:hover {
      p {
        color: $color_text_primary !important;
      }
    }

    p {
      color: $color_text_primary !important;
      font-weight: 600;
    }
  }
}

.menu-when-hide {
  position: absolute;
  top: 250px;
  right: 0;
  width: 41px;
  height: 0px;

  z-index: 1000;
  overflow: hidden;
  transition: $transtion_time;

  display: flex;
  flex-direction: column;
  gap: 20px;

  background-color: #fff;

  a {
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    .naviga-icon {
      width: 24px;
      height: 24px;

      svg {
        width: 100%;
        height: 100%;

        g {
          fill: $color_text_primary !important;
        }
      }
    }
  }

  &-icon {
    &:hover {
      background-color: #d8d7d7;
      transition: all 0.4s;
    }
  }

  // &-icon {
  //   display: block;
  //   width: 100%;
  //   height: 48px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   transition: $transtion_time;
  //   .item-menu-logo {
  //     display: block;
  //   }
  //   .item-menu-logo-hover {
  //     display: none;
  //   }
  //   &:hover {
  //     background-color: #d8d7d7 !important;
  //     .item-menu-logo {
  //       display: none;
  //     }
  //     .item-menu-logo-hover {
  //       display: block;
  //     }
  //   }
  // }
}
