.statistic-wrap {
  box-shadow: 0px 0px 16px 0px #00000026;

  .statistic-item {
    .statistic-content {
      padding: 24px 24px 24px 24px;
      position: relative;

      .statistic-image {
        position: absolute;
        right: 16px;
        top: 16px;

        img {
          width: 80px;
          height: 80px;
        }

        @media screen and (max-width: 991px) {
          img {
            width: 50px;
            height: 50px;
          }
        }

        @media screen and (max-width: 768px) {
          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .statistic-title {
        margin-bottom: 24px;
        p {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          color: #111111;
          opacity: 0.7;
        }
      }

      .statistic-value {
        margin-bottom: 24px;

        p {
          font-size: 48px;
          font-weight: 700;
          line-height: 56px;
        }
      }

      .statistic-difference {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 36px;
          height: 36px;
        }

        p {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          margin: 0px;
        }
      }
    }
  }
}
