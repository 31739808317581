.event-wrap {
  @media screen and (max-width: 1200px) {
    .filter-wrap {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 768px) {
    .filter-wrap {
      flex-direction: column;

      .form-control {
        width: 100% !important;
      }
    }
  }
}
