.checkout-custom {
    width: auto;
    display: flex;
    position: relative;
    padding-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.5s !important;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        margin: 5px;
    }

    &-mark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06);
        transition: 0.5s !important;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 8px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &-text {
        position: absolute;
        top: 5px;
        left: 30px;
        width: auto;
        padding-left: 5px;
        transition: 0.5s !important;

        p {
            font-size: 0.8rem;
            margin-bottom: 0;
        }

        &:hover {
            text-shadow: 0 0 1px black;
        }
    }

    &:hover input ~ .checkout-custom-mark {
        background-color: rgb(227, 235, 236);
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06), 0.3px 0px 4px rgba(29, 28, 28, 0.3);
    }

    input:checked ~ .checkout-custom-mark {
        background-color: #2196f3;
    }
    
    input:checked ~ .checkout-custom-mark:after {
        display: block;
    }
}
