.course {
  .upload-field-ebook {
    overflow: visible;
  }
  .course-custom-input {
    min-height: 48px;
  }
  .upload-field {
    max-height: 48px;
  }
  .icon-file {
    max-width: 32px !important;
    max-height: 48px !important;
  }
  .kn-file {
    min-height: 48px !important;
    max-height: 48px;
  }
  .name-file {
    font-family: SFUIText;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #8898aa;
  }
  .kn-file-download {
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    position: relative;
    // img of uplaod ôn dow
    img {
      display: block;
      margin: right;
      width: 16px;
      cursor: pointer !important;

      position: absolute;
      bottom: 15px;
      right: 14px;
    }
  }
  .kn-file-upload {
    min-height: 48px !important;
    padding: 10px 20px;
    display: flex;
    position: relative;
    cursor: pointer;
    img {
      display: block;
      margin: right;
      width: 17px;
      cursor: pointer !important;

      position: absolute;
      bottom: 14px;
      right: 17px;
    }
  }
  .remove-custom {
    top: 20px;
    right: -28px;
  }

  // close file input
  .close-file {
    position: absolute;
    top: 42px;
    right: -10px;
    cursor: pointer;
    transition: $transtion_time;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
}

.course-wrap {
  .header-wrap {
    display: flex;
    justify-content: space-between;

    .filter-wrap {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      width: 100%;

      .filter-right {
        display: flex;
        gap: 16px;
      }

      .form-group {
        margin-bottom: 30px !important;

        .form-control {
          width: 250px;
        }
      }
    }

    .btn-add-new {
      margin-right: 15px;
    }
  }
}

.content__flex-end {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  a {
    display: flex;
    justify-content: flex-end;
  }
}

.donate-list {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;

  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 26px;
  height: 46px;
  color: #0e2431;

  border-radius: 4px !important;

  svg {
    vertical-align: -2px;
  }
}

.form-search-input {
  width: 100%;
  color: black;
}

.upload-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media screen and (max-width: 1200px) {
  .course-wrap {
    .header-wrap {
      // justify-content: space-around;

      .row {
        padding: 0px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .content__flex-end {
    margin-bottom: 15px;

    a {
      justify-content: flex-start;
    }
  }

  .form-group,
  .form-search {
    margin-bottom: 15px !important;
  }
}
