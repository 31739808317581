.paginate-wrapper {
  margin-top: 30px;

  a:hover {
    text-decoration: none;
  }

  li {
    padding: 0 8px;

    span.lnr {
      color: #474747;
      font-size: 14px;
      font-weight: 600;
      vertical-align: middle;
    }

    a {
      height: 24px;
      width: 24px;
      margin: 0 2px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      vertical-align: middle;
      color: #54667a;
      transition: all 0.2s;
      border-radius: 2px !important;

      &:hover {
        background: #0e2431;
        color: #fff;
        transition: all 0.2s;

        span.lnr {
          color: #fff;
        }
      }
    }

    &.active {
      a {
        width: 24px;
        height: 24px;
        background: #0e2431;
        color: #fff;
      }
    }

    &.disabled {
      a {
        cursor: default;
      }

      span.lnr {
        color: #ccc;
      }
    }
  }
}
