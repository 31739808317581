.student {
  box-shadow: 0px 0px 16px 0px #00000026;

  &-wrap {
    padding: 16px;
    background-color: #ffffff;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    border-bottom: 1px solid #e6e6e6;

    p {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #0e2431;
      margin-bottom: 0px;
    }

    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;

      color: #0e2431;
      text-decoration: none;

      &:hover {
        color: #ed1c24;
      }
    }
  }

  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &-image {
      min-width: 40px;
      height: 40px;
      margin-right: 16px;
      border-radius: 50%;
      overflow: hidden;
      gap: 16px;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 0px;
      flex: 1;

      &-name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100px;
      }

      &-club {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (min-width: 991px) and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 0px;
      }
    }
  }
}
