.student {
  padding: 24px 24px !important;
  .col-md-12 {
    select option {
      font-family: 'SF-Pro-Display';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #0e2431;
    }
  }
}
